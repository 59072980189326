function gallery() {
    var swiper = new Swiper(".gallery .mySwiper", {
        slidesPerView: 3,
        spaceBetween: 8,
        freeMode: true,
        watchSlidesProgress: true,
        direction: "horizontal",
    });
    var swiper2 = new Swiper(".gallery .mySwiper2", {
        spaceBetween: 10,
        slidesPerView: 1,
        thumbs: {
            swiper: swiper,
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });
}
function sliderRcRent() {
    var swiper = new Swiper('.rc-rent .swiper', {
        slidesPerView: "3.3",
        spaceBetween: 34,
        navigation: {
            nextEl: '.rc-rent .swiper-button-next',
            prevEl: '.rc-rent .swiper-button-prev',
        },
        breakpoints: {
            320: {
                slidesPerView: "2",
                spaceBetween: 8,
            },
            390: {
                slidesPerView: "2.5",
                spaceBetween: 8,
            },
            577: {
                slidesPerView: "2.5",
                spaceBetween: 16,
            },
            1401: {
                slidesPerView: "3",
                spaceBetween: 24,
            },
            1601: {
                slidesPerView: "3.3",
                spaceBetween: 34,
            },
        }
    })
}
function sliderRcInfo() {
    var swiper = new Swiper('.rc-info .swiper', {
        slidesPerView: "3",
        spaceBetween: 8,
        navigation: {
            nextEl: '.rc-info .swiper-button-next',
            prevEl: '.rc-info .swiper-button-prev',
        },
        breakpoints: {
            320: {
                slidesPerView: "1",
            },
            500: {
                slidesPerView: "2",
            },
            1401: {
                slidesPerView: "3",
            },
        }
    })
}
function sliderApartments() {
    var swiper = new Swiper('.apartments .swiper', {
        slidesPerView: "auto",
        spaceBetween: 60,
        slideToClickedSlide: 'true',
        breakpoints: {
            320: {
                spaceBetween: 30,
            },
            993: {
                spaceBetween: 60,
            },
        }
    })
}
function sliderFile() {
    var swiper = new Swiper('.form-file__wrap .swiper', {
        slidesPerView: 4,
        spaceBetween: 16,
        breakpoints: {
            320: {
                slidesPerView: 2,
                spaceBetween: 4,
            },
            390: {
                slidesPerView: 3,
                spaceBetween: 4,
            },
            577: {
                slidesPerView: 3,
                spaceBetween: 16,
            },
            1801: {
                slidesPerView: 4,
                spaceBetween: 16,
            },
        }
    })
}

$(document).ready(function() {

    gallery();
    sliderRcRent();
    sliderRcInfo();
    sliderApartments();
    sliderFile();

    // Tab
    $(".tab").click(function () {
        let path = $(this).attr("data-tab-path")
        $(this).siblings(".tab").removeClass("tab--active")
        $(this).addClass("tab--active")

        $(this).parents(".tabs__inner").find(".tab__content").removeClass("tab__content--active")
        $(this).parents(".tabs__inner").find(`.tab__content[data-content-path="${path}"]`).addClass("tab__content--active")
    })

    //
    $('.login__btn').click(function () {
        $(this).parents('.login__btns').addClass('login__btns--active')
        $('.login__tabs-body').addClass('login__tabs-body--active')
        $('.prev-btn').addClass('prev-btn--active')
    })

    $('.prev-btn').click(function () {
        $('.login__btns').removeClass('login__btns--active')
        $('.login__tabs-body').removeClass('login__tabs-body--active')
        $('.prev-btn').removeClass('prev-btn--active')
    })

    // Password
    $('.password').click(function () {
        if ($(this).is(':checked')){
            $(this).parents('.password-icon').siblings('.password-input').attr('type', 'password');
            $(this).parents('.password-icon').removeClass('password-icon--active')
        } else {
            $(this).parents('.password-icon').siblings('.password-input').attr('type', 'text');
            $(this).parents('.password-icon').addClass('password-icon--active')
        }
    })

    // Filter
    $('.header__search-filter').click(function () {
        $(this).toggleClass('header__search-filter--active')
        $('.filter').toggleClass('filter--active')
        $('.bg-body').toggleClass('bg-body--active')
        $('body').toggleClass('fixed-body')
    })

    // Menu
    $('.menu-btn').click(function () {
        $('.menu').toggleClass('menu--active')
        $('body').toggleClass('fixed-body2')
    })

    // Select
    $(".custom_select select").niceSelect()

    // cAllcheckbox
    $(".map-filter__btn").click(function () {
        var t = $(this).siblings('.map-filter__checkboxes').children('.map-filter__checkbox');
        t.find('input[type=checkbox]').each(function() {
            this.checked = true;
        });
    });

    //BtnChoose
    $('.rc-scheme__card-btn').click(function () {
        $(this).toggleClass('rc-scheme__card-btn--active')
    })

})